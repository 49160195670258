import { RouteRecordRaw } from 'vue-router';

import { viewLazyLoad } from '@/utils/lazyLoadUtil';

export const dashboardRoutes: Array<RouteRecordRaw> = [
    {
        name: 'campaigns',
        path: '/campaigns',
        component: viewLazyLoad('Campaigns'),
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'track_drivers',
        path: '/track/drivers',
        component: viewLazyLoad('TrackDrivers'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'driver',
        path: '/users/driver/:id',
        component: viewLazyLoad('Driver'),
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'adhesive',
        path: '/user/adhesive/:id',
        component: viewLazyLoad('Adhesive'),
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'advertiser',
        path: '/user/advertiser/:id',
        component: viewLazyLoad('Advertiser'),
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'users',
        path: '/users',
        component: viewLazyLoad('Users'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'create_driver',
        path: '/user/driver/create',
        component: viewLazyLoad('CreateDriver'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'create_advertiser',
        path: '/user/advertiser/create',
        component: viewLazyLoad('CreateAdvertiser'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'create_adhesiver',
        path: '/user/adhesiver/create',
        component: viewLazyLoad('CreateAdhesiver'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'create_campaign',
        path: '/user/campaign/create',
        component: viewLazyLoad('CreateCampaign'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'campaign',
        path: '/user/campaign/:id',
        component: viewLazyLoad('Campaign'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'graphics',
        path: '/graphics',
        component: viewLazyLoad('Graphics'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'create_graphic',
        path: '/graphic/create',
        component: viewLazyLoad('CreateGraphic'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'graphic',
        path: '/graphic/:id',
        component: viewLazyLoad('Graphic'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'companies',
        path: '/companies',
        component: viewLazyLoad('Companies'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'create_company',
        path: '/company/create',
        component: viewLazyLoad('CreateCompany'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'company',
        path: '/company/:id',
        component: viewLazyLoad('Company'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'cities',
        path: '/cities',
        component: viewLazyLoad('Cities'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'create_city',
        path: '/city/create',
        component: viewLazyLoad('CreateCity'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'city',
        path: '/city/:id',
        component: viewLazyLoad('City'),
        meta: {
            requiresAuth: true
        },
    }
];